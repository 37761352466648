import Filter from "components/filter";
import { format, parseISO } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import ReactJson from "react-json-view";
import XMLViewer from "react-xml-viewer";

const Logs = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [textSearch, setTextSearch] = useState("");

  const token = useMemo(() => {
    return localStorage.getItem("auth");
  });

  const handleSearch = useCallback(async () => {
    setLoading(true);
    const headersList = {
      Authorization: `Bearer ${token}`,
    };
    const endpoint = `${process.env.REACT_APP_URL_BASE}/system/logs?id=${textSearch}`;
    fetch(endpoint, {
      method: "GET",
      headers: headersList,
    })
      .then((res) => res.json())
      .then((json) => {
        setData(json);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token, textSearch]);

  return (
    <div className="mt-5 grid h-full grid-cols-1 ">
      <Filter
        showFilterText={true}
        setTextSearch={setTextSearch}
        textSearch={textSearch}
        handleFilter={handleSearch}
      />

      <div className="mt-[10px] flex flex-col gap-1">
        {data &&
          data.map((item, index) => {
            const [id, type, ...rest] = item.message.split("|");
            return (
              <div
                className="flex flex-col  rounded-lg border border-gray-200 bg-white p-6 shadow dark:border-gray-700 dark:bg-gray-800"
                key={index}
              >
                <div className="flex flex-row justify-between">
                  <span>
                    {format(parseISO(item.timestamp), "yyyy-MM-dd hh:mm")}
                  </span>
                  <span>{item.level}</span>
                </div>
                <div>
                  <span>{type}</span>
                  {rest.map((item) => {
                    if (item.startsWith("{")) {
                      return (
                        <ReactJson
                          src={JSON.parse(item)}
                          theme={"monokai"}
                          collapsed={true}
                        />
                      );
                    } else if (item.includes("xml")) {
                      return <XMLViewer xml={item} />;
                    } else {
                      return <div>{item}</div>;
                    }
                  })}

                  <div>
                    LINK
                    <a >Revisar</a>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Logs;
