import ModalGeneral from "components/modal";
import React, { useEffect, useState } from "react";

export const AcuseCep = ({ close, token, clave }) => {
  const [formData, setFormData] = useState({});
  const { REACT_APP_URL_BASE } = process.env;

  useEffect(() => {
    httpApi();
  }, []);

  const httpApi = async (textComment) => {
    const options = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(textComment),
    };
    const endpoint = `${REACT_APP_URL_BASE}/solicitudes/${clave}/acuse-sep`;
    fetch(endpoint, options)
      .then((res) => res.json())
      .then((json) => {
        if(json) {
          setFormData(json);
        }
      })
      .catch((error) => {
        return null;
      });
  };

  return (
    <ModalGeneral title={"ACUSE CEP"} close={close}>
      <div className="flex h-[300px] w-[350px] flex-col gap-2 px-4 py-4">
        <div
          className={`flex flex-row gap-2 ${
            formData?.coincideNombre ? "bg-green-300" : "bg-red-300"
          }`}
        >
          <span>
            {formData?.coincideNombre
              ? "El nombre coincide"
              : "El nombre no coincide"}
          </span>
        </div>
        <div className="flex flex-row gap-2">
          <span>RfcCep</span>
          <span>{formData?.rfcCep}</span>
        </div>
        <div className="flex flex-row gap-2">
          <span>UrlCEP</span>
          <br />
          <a href={formData?.urlCEP} target="_blank">
            Ver acuse
          </a>
        </div>
        <div className="flex flex-row gap-2">
          <span>NombreCep</span>
          <span>{formData?.nombreCep}</span>
        </div>
        <div className="flex flex-row gap-2">
          <span>Empresa</span>
          <span>{formData?.empresa}</span>
        </div>
        <div className="flex flex-row gap-2">
          <span>FechaOperacion</span>
          <span>{formData?.fechaOperacion}</span>
        </div>
        {/* <div className="flex flex-row gap-2">
          <span>Sello</span>
          <span>{formData?.sello}</span>
        </div> */}
        <div className="flex flex-row gap-2">
          <span>ClaveRastreo</span>
          <span>{formData?.claveRastreo}</span>
        </div>
        <div className="flex flex-row gap-2">
          <span>CuentaBeneficiario</span>
          <span>{formData?.cuentaBeneficiario}</span>
        </div>
      </div>
    </ModalGeneral>
  );
};
