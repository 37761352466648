import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import Swal from "sweetalert2";
import { object, string } from "yup";

const createValidationSchema = () => {
  return object().shape({
    name: string().required("Ingresa el nombre del banco"),
    codeISO: string().required("Ingresa el codigo ISO"),
  });
};

const FormBank = ({
  title,
  defaultValues,
  role,
  onClose = () => {},
  endpoint,
  token,
  handleSucces,
}) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (defaultValues) setFormData(defaultValues);
  }, [defaultValues]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isPasswordRequired = !defaultValues;
    const validationSchema = createValidationSchema(isPasswordRequired);
    if (role) {
      formData.rolId = role;
    }

    validationSchema
      .validate(formData, { abortEarly: false })
      .then(function () {
        const fullEndpoint = defaultValues
          ? `${endpoint}/system/banks/${defaultValues.id}`
          : `${endpoint}/system/banks`;
        fetch(fullEndpoint, {
          method: defaultValues?.id ? "PUT" : "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            ...formData,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .finally(() => {
            if (typeof handleSucces === "function") handleSucces();
          });
      })
      .catch(function (err) {
        const erros = err.inner.map((item) => item.message).join("\n");
        Swal.fire({
          title: "Error",
          text: erros,
          type: "error",
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="w-[650px] rounded-lg bg-white p-8">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-xl font-semibold">{title ?? ""}</h2>
          <button onClick={onClose} className="text-gray-500">
            <MdClose />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col ">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Nombre del banco"
              className="mb-4 w-full border p-2"
            />
            <input
              type="text"
              name="codeISO"
              value={formData.codeISO}
              onChange={handleChange}
              placeholder="Codigo iso"
              className="mb-4 w-full border p-2"
            />
          </div>

          <button
            className="rounded-lg bg-blue-500 px-4 py-2 text-white"
            type="submit"
          >
            Guardar
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormBank;
