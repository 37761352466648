import { useCallback, useMemo, useState } from "react";
import Swal from "sweetalert2";

const Datas = () => {
  const token = useMemo(() => {
    return localStorage.getItem("auth");
  });

  const [loading, setLoading] = useState(false);

  const savePeriodos = useCallback(
    async (file) => {
      setLoading(true)
      const headersList = {
        Authorization: `Bearer ${token}`,
      };

      const bodyContent = new FormData();
      bodyContent.append("file", file);
      const endpoint = `${process.env.REACT_APP_URL_BASE}/system/upload-periodos`;

      fetch(endpoint, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      }).finally(() => {
        Swal.fire({
          title: "Se cargaron los periodos",
        });
        setLoading(false);
      });
    },
    [token]
  );

  return (
    <div className="mt-5 flex h-full gap-2">
      <div className="flex h-[250px] grow flex-col items-center justify-center rounded-lg bg-green-300">
        <h4>Subir periodos</h4>
        <label htmlFor="data">
          <svg
            width={100}
            height={100}
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 35.317 35.317"
            fill="#000000"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <path d="M26.969,12.34c-0.748,0-1.492,0.105-2.221,0.309c-0.898-3.725-4.209-6.366-8.102-6.366 c-3.716,0-7.015,2.523-8.023,6.064c-0.092-0.002-0.185-0.007-0.277-0.007C3.746,12.34,0,16.088,0,20.685 c0,4.604,3.746,8.348,8.346,8.348h18.623c4.602,0,8.348-3.744,8.348-8.348C35.316,16.088,31.57,12.34,26.969,12.34z M26.969,27.894 H8.346c-3.973,0-7.209-3.234-7.209-7.209c0-3.973,3.236-7.203,7.209-7.203c0.223,0,0.443,0.012,0.662,0.033l0.5,0.045l0.107-0.488 c0.723-3.275,3.68-5.648,7.031-5.648c3.553,0,6.541,2.545,7.105,6.053l0.109,0.68l0.648-0.236c0.803-0.291,1.633-0.438,2.459-0.438 c3.975,0,7.207,3.23,7.207,7.203C34.176,24.66,30.943,27.894,26.969,27.894z"></path>
                <path d="M22.398,15.363c-0.217-0.217-0.574-0.217-0.787,0l-6.578,6.576l-3.389-3.406 c-0.217-0.221-0.57-0.221-0.789,0l-1.188,1.182c-0.219,0.215-0.219,0.574,0,0.793l4.967,4.994c0.215,0.217,0.568,0.217,0.789,0 l8.162-8.162c0.223-0.217,0.223-0.574,0-0.791L22.398,15.363z"></path>
              </g>
            </g>
          </svg>
          {loading && <h4>Cargando archivo</h4>}
        </label>
        <input
          id="data"
          type="file"
          style={{ display: "none" }}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={(e) => {
            if (e.target.files) {
              savePeriodos(e.target.files[0]);
            }
          }}
        />
      </div>
    </div>
  );
};

export default Datas;
