const ButtonGeneral = ({ handleClick, children, style, visible, className }) => {
  if (!visible) return null;

  return (
    <button
      style={{ ...style }}
      onClick={handleClick}
      className={`hover:bg-brand-500-hover active:bg-brand-500-hover dark:hover:bg-brand-500-hover dark:active:bg-brand-500-hover mr-5 w-[140px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 dark:bg-brand-500 dark:text-white ${className}`}
    >
      {children}
    </button>
  );
};

export default ButtonGeneral;
