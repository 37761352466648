import { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

const useLogTracker = (moduleName) => {
  const sessionKey = `session-active-${moduleName}`;
  const { token } = useSelector((state) => state.auth);
  const { REACT_APP_URL_BASE } = process.env;

  const registerEvent = useCallback(
    async (type, details = {}) => {
      const event = {
        module: moduleName,
        ...details,
        accion: type,
      };

      try {
        const options = {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(event),
        };
        fetch(REACT_APP_URL_BASE + "/system/logs", options).finally(() => {
          console.log(
            `Event ${type} registered for module ${moduleName}`,
            details
          );
        });
      } catch (error) {
        console.error("Error registering event:", error);
      }
    },
    [moduleName, token]
  ); // Dependencias: `moduleName` y `token`

  useEffect(() => {
    const isSessionActive = sessionStorage.getItem(sessionKey);

    // Registrar evento de entrada
    console.log(isSessionActive);
    if (!isSessionActive) {
      registerEvent("Entro");
      sessionStorage.setItem(sessionKey, "true");
    }

    // Maneja la visibilidad para registrar evento de salida
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        registerEvent("Salio");
        sessionStorage.removeItem(sessionKey);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [moduleName]);

  // Función para registrar eventos personalizados
  const registerCustomEvent = useCallback((eventType, details) => {
    registerEvent(eventType, details);
  }, []);

  return { registerCustomEvent };
};

export default useLogTracker;
