// authSlice.ts

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countSolicitadas: 0,
  countDevueltas: 0,
  countAtender: 0,
  countCanceladas: 0,
  countReasignar: 0,
  countProceso: 0,
  token: null,
  rol: 0,
  routes: [],
  indexUpdate: 0,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setNotificacion(state, action) {
      const { countProceso, countDevueltos, countAtender } = action.payload;
      state.countAtender = countAtender;
      state.countDevueltas = countDevueltos;
      state.countProceso = countProceso;
    },
    setDataAuht(state, action) {
      const { token, rol, routes } = action.payload;
      state.token = token;
      state.rol = rol;
      state.routes = routes;
    },
    setUpdatePage(state, action) {
      state.indexUpdate = state.indexUpdate + action.payload;
    },
    logout(state, action) {
      state.token = null;
    },
  },
});

export const { setNotificacion, setDataAuht, setUpdatePage, logout } =
  authSlice.actions;

export default authSlice.reducer;
