const ModalGeneral = ({ title, children, close, w }) => {
  const renderHeader = () => {
    return (
      <div className="border-blueGray-200 flex items-start justify-between rounded-t border-b border-solid p-5">
        <h3 className="text-3xl font-semibold">{title}</h3>
        <button
          className="bg-transparent inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-gray-400 ms-auto hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={() => close()}
        >
          <svg
            className="h-3 w-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
    );
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(5px)",
          zIndex: 0,
        }}
      ></div>
      <div className="relative my-6 mx-auto w-auto">
        <div className="relative flex max-h-screen w-[full] flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
          {renderHeader()}
          {children}
          <div className="border-blueGray-200 flex items-center justify-end rounded-b border-t border-solid p-6"></div>
        </div>
      </div>
    </div>
  );
};

export default ModalGeneral;
