import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import { useMemo } from "react";
import SidebarCard from "./components/SidebarCard";
import { useSelector } from "react-redux";
import routes from "routes.js";

const Sidebar = ({ open, onClose }) => {
  const { routes: $routes } = useSelector((state) => state.auth);
  const { REACT_APP_AMBIENTE } = process.env;

  const permissions = useMemo(() => {
    if ($routes) {
      const getDescriptions = (route) => {
        const descriptions = [route.id];

        if (route.children) {
          const childDescriptions = route.children.reduce(
            (childDescriptions, child) => {
              return [...childDescriptions, ...getDescriptions(child)];
            },
            []
          );
          descriptions.push(...childDescriptions);
        }

        return descriptions;
      };

      // Aplicar la función recursiva en el array de rutas
      return $routes.reduce((descriptions, route) => {
        return [...descriptions, ...getDescriptions(route)];
      }, []);
    }
  }, [$routes]);

  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[50px] flex items-center`}>
        {REACT_APP_AMBIENTE == 0 ? (
          <div className="ml-1 mt-1 h-2.5 font-poppins text-[18px] font-bold uppercase text-red-400 dark:text-white mb-2">
            AMBIENTE DE <br />
            <span className="font-medium text-red-400">PRUEBAS</span>
          </div>
        ) : (
          <div className="ml-1 mt-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
            MI <span className="font-medium">DINERIO</span>
          </div>
        )}
      </div>
      <div className="mb-7 mt-[58px] h-px bg-gray-300 dark:bg-white/30" />

      <ul className="mb-auto pt-1">
        <Links routes={routes} permissions={permissions} />
      </ul>

      <div className="flex justify-center">
        <SidebarCard />
      </div>
    </div>
  );
};

export default Sidebar;
