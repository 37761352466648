const BtnNew = ({
  handleClick,
  children,
  style,
  visible,
  bgBtn,
  Icon,
  colorIcon,
}) => {
  if (!visible) return null;

  return (
    <button
      style={{ ...style }}
      onClick={handleClick}
      className={`flex h-[30px] items-center justify-start gap-2 rounded-[10px] p-2 px-2 text-white ${bgBtn ?? 'bg-brand-400'}`}
    >
      {Icon && <Icon className={colorIcon} />}
      {children}
    </button>
  );
};

export default BtnNew;
