export default function Badge({ value }) {
  const setValue = ($number) => {
    if ($number >= 10) {
      return "10+";
    }
    return $number ?? 0;
  };
  return (
    <span class="relative ml-2 grid  select-none items-center whitespace-nowrap rounded-full bg-white-200 py-1 px-2 text-xs font-bold uppercase">
      {setValue(value)}
    </span>
  );
}
