import InputField from "components/fields/InputField";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDataAuht } from "../../redux/slice/auth";

export default function SignIn() {
  const [form, setForm] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const { REACT_APP_URL_BASE, REACT_APP_AMBIENTE } = process.env;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAuth = async ({ email, password }) => {
    const options = {
      method: "POST",
      body: JSON.stringify({ email, password, isApp: false }),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    };

    setLoading(true);
    const endpoint = `${REACT_APP_URL_BASE}/auth`;
    const response = await fetch(endpoint, options)
      .then((res) => res.json())
      .catch((error) => {
        return null;
      });
    setLoading(false);

    if (typeof response === "string") return;
    localStorage.setItem("auth", response.jwt);
    localStorage.setItem("rol", response.rolId);

    await dispatch(
      setDataAuht({
        token: response.jwt,
        routes: response.permission,
        rol: response.rolId,
      })
    );
    return navigate("/admin/default");
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4
          className={`mb-2.5 text-4xl font-bold dark:text-white ${
            REACT_APP_AMBIENTE == 0 ? "text-red-500" : "text-navy-700"
          }`}
        >
          {REACT_APP_AMBIENTE == 0 ? "AMBIENTE DE PRUEBAS" : " Iniciar sesión"}
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Ingresa usuario y contraseña
        </p>

        <InputField
          variant="auth"
          extra="mb-3"
          label="Correo*"
          placeholder=""
          id="email"
          type="text"
          value={form.email}
          onChange={(event) => {
            setForm((old) => {
              return { ...old, email: event.target.value };
            });
          }}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Contraseña"
          placeholder=""
          id="password"
          type="password"
          value={form.password}
          onChange={(event) => {
            setForm((old) => {
              return { ...old, password: event.target.value };
            });
          }}
        />
        <div className="mb-4 flex items-center justify-between px-2 text-red-400">
          {error}
        </div>
        <button
          className="linear hover:bg-brand-500-hover active:bg-brand-500-hover dark:hover:bg-brand-500-hover dark:active:bg-brand-500-hover mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 dark:bg-brand-500 dark:text-white"
          onClick={() => {
            handleAuth({
              email: form.email,
              password: form.password,
            });
          }}
          disabled={loading}
        >
          {loading ? "Verificando" : "Entrar"}
        </button>
      </div>
    </div>
  );
}
