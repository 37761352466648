import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import Swal from "sweetalert2";
import { object, string } from "yup";

const createValidationSchema = (isPasswordRequired) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  return object().shape({
    name: string().required("El nombre es obligatorio"),
    email: string()
      .email("El correo no es valido")
      .required("El correo es obligatorio"),
    phone: string()
      .required("El teléfono es obligatorio")
      .matches(phoneRegExp, "El teléfono no es valido"),
    rolId: string().required("Selecciona el rol"),
    password: isPasswordRequired
      ? string().required("Ingresa la contraseña")
      : string().notRequired(),
  });
};

const FormUser = ({
  title,
  defaultValues,
  role,
  onClose = () => {},
  endpoint,
  token,
  handleSucces,
}) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (defaultValues) setFormData(defaultValues);
  }, [defaultValues]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isPasswordRequired = !defaultValues;
    const validationSchema = createValidationSchema(isPasswordRequired);
    if (role) {
      formData.rolId = role;
    }

    validationSchema
      .validate(formData, { abortEarly: false })
      .then(function () {
        const fullEndpoint = defaultValues
          ? `${endpoint}/users/${defaultValues.id}`
          : `${endpoint}/users`;
        fetch(fullEndpoint, {
          method: defaultValues?.id ? "PUT" : "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            ...formData,
            countRequest: 1,
            secondLastName: "",
            lastName: formData.lastName,
            password: formData.password,
            codeZell: formData.codeZell
          }),
        })
          .then((res) => {
            return res.json();
          })
          .finally(() => {
            if (typeof handleSucces === "function") handleSucces();
          });
      })
      .catch(function (err) {
        const erros = err.inner.map((item) => item.message).join("\n");
        Swal.fire({
          title: "Error",
          text: erros,
          type: "error",
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="w-[650px] rounded-lg bg-white p-8">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-xl font-semibold">
            {title ?? "Edición de usuario"}
          </h2>
          <button onClick={onClose} className="text-gray-500">
            <MdClose />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col ">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Nombre"
              className="mb-4 w-full border p-2"
            />
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Apellidos"
              className="mb-4 w-full border p-2"
            />

            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Celular"
              className="mb-4 w-full border p-2"
            />

            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Correo"
              className="mb-4 w-full border p-2"
            />

            {role == 2 && (
              <input
                type="text"
                name="codeZell"
                value={formData.codeZell}
                onChange={handleChange}
                placeholder="EmployeeId"
                className="mb-4 w-full border p-2"
              />
            )}
            {!defaultValues && (
              <input
                type="text"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Contraseña"
                className="mb-4 w-full border p-2"
              />
            )}

            {!role && (
              <select
                className="mb-4 w-full border p-2"
                onChange={handleChange}
                name="rolId"
                value={formData.rolId}
              >
                <option>Selecciona el rol</option>
                <option value={4}>Mesa de control nivel 1</option>
                <option value={6}>Mesa de control nivel 2</option>
                <option value={7}>Mesa de control nivel 3</option>
                <option value={8}>Mesa de control nivel 4</option>
              </select>
            )}
          </div>

          <button
            className="rounded-lg bg-blue-500 px-4 py-2 text-white"
            type="submit"
          >
            Guardar
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormUser;
