import ModalGeneral from "components/modal";
import { useFetch } from "hooks/useFetch";
import React, { useMemo, useState } from "react";
import Swal from "sweetalert2";

export const AsignarUsuario = ({ close, id, registerCustomEvent }) => {
  const { REACT_APP_URL_BASE } = process.env;

  const [searchTerm, setSearchTerm] = useState("");

  const token = useMemo(() => {
    return localStorage.getItem("auth");
  });

  const { data, hasError } = useFetch(`${REACT_APP_URL_BASE}/users?idRole=2`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data?.filter((item) => {
    const firstName = item.firstName ? item.firstName.toLowerCase() : "";
    const lastName = item.lastName ? item.lastName.toLowerCase() : "";
    const secondLastName = item.secondLastName
      ? item.secondLastName.toLowerCase()
      : "";
    const email = item.email ? item.email.toLowerCase() : "";

    return (
      firstName.includes(searchTerm.toLowerCase()) ||
      lastName.includes(searchTerm.toLowerCase()) ||
      secondLastName.includes(searchTerm.toLowerCase()) ||
      email.includes(searchTerm.toLowerCase())
    );
  });

  const handleConfirmAssignment = async ({ id, user }) => {
    try {
      const response = await fetch(
        `${REACT_APP_URL_BASE}/solicitudes/${id}/asignar`,
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: user,
        }
      );
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "La asignación fue exitosa",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Ocurrió un error al asignar el usuario",
        showConfirmButton: false,
        timer: 3000,
      });
    } finally {
      close();
    }
  };

  const handleAssignUser = (user) => {
    Swal.fire({
      title: "Confirmación.",
      text: `¿Está seguro que desea asignar al asesor ${user.name} ${user.lastName}?`,
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cerrar",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        handleConfirmAssignment({
          id: id,
          user: user.id,
        });

        registerCustomEvent(
          `Reasigno la solicitud a ${user.name} ${user.lastName}`,
          {
            solicitudId: id,
          }
        );
      }
    });
  };

  return (
    <ModalGeneral title="Asignar asesor" close={close}>
      <div className="flex h-[650px] w-[550px] flex-col overflow-y-scroll px-5">
        <input
          type="text"
          placeholder="Buscar usuario"
          value={searchTerm}
          onChange={handleSearchChange}
          className="mb-4 rounded border border-gray-300 p-2"
        />
        {hasError && <p>Error loading data</p>}
        <ul className="divide-y divide-gray-200">
          {filteredData &&
            filteredData.map((item, index) => (
              <li
                key={index}
                className="flex items-center justify-between py-4"
              >
                <div className="flex flex-col">
                  <span className="text-lg font-medium">
                    {item.name} {item.lastName} {item.secondLastName}
                  </span>
                  <span className="text-sm text-gray-600">{item.email}</span>
                </div>
                <button
                  onClick={() => handleAssignUser(item)}
                  className="hover:bg-brand-500-hover active:bg-brand-500-hover dark:hover:bg-brand-500-hover dark:active:bg-brand-500-hover mr-5 w-[100px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 dark:bg-brand-500 dark:text-white"
                >
                  Asignar
                </button>
              </li>
            ))}
        </ul>
      </div>
    </ModalGeneral>
  );
};
