import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import useSignalR from "hooks/useSignalR";
import { logout } from "./redux/slice/auth";

const App = () => {
  useSignalR();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleSessionEnd = () => {
      console.log("cerrando sesion");
      dispatch(logout());
    };

    window.addEventListener("beforeunload", handleSessionEnd);

    return () => {
      window.removeEventListener("beforeunload", handleSessionEnd);
    };
  }, [dispatch]);
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="/" element={<Navigate to="/auth" replace />} />
    </Routes>
  );
};

export default function Root() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
}
