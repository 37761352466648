import { useEffect, useState } from "react";


export const useFetch = (url, options = {}) => {
    const [state, setState] = useState({
        data: null,
        isLoading: true,
        hasError: null,
    });

    const getFetch = async () => {
        setState({
            ...state,
            isLoading: true,
        });

        try {
            const resp = await fetch(url, options);
            const data = await resp.json();

            setState({
                data,
                isLoading: false,
                hasError: null,
            });
        } catch (error) {
            setState({
                data: null,
                isLoading: false,
                hasError: error,
            });
        }
    };

    useEffect(() => {
        getFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    return {
        data: state.data,
        isLoading: state.isLoading,
        hasError: state.hasError,
    };
};