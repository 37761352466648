import { useMemo, useState } from "react";
import Grid from "components/grid";
import { useEffect } from "react";
import Filter from "components/filter";
import FormBank from "./form-user";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

const Banks = () => {
  const [add, setAdd] = useState(false);
  const [defaultValues, setDefaultValues] = useState(false);
  const [loading, setLoading] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [data, setData] = useState();

  const token = useMemo(() => {
    return localStorage.getItem("auth");
  });

  useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    const endpoint = `${process.env.REACT_APP_URL_BASE}/system/banks`;
    setLoading(true);
    fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setData(json);
      })
      .finally(() => {
        setLoading(false);
        setDefaultValues();

        setAdd(false);
      });
  };

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 ">
        <Filter
          showFilterText={true}
          setTextSearch={setTextSearch}
          textSearch={textSearch}
          handleAdd={() => {
            setAdd(true);
          }}
          titleAdd="Agregar bancos"
        />
        {loading ? (
          <div className="flex h-screen items-center justify-center">
            <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
          </div>
        ) : (
          data && (
            <Grid
              colums={[{ name: "Codigo" }, { name: "Nombre" }, { name: "" }]}
              data={data ? data : []}
              textFilter={textSearch}
              keysFilter={["name", "codeISO"]}
              ComponentRow={({ item }) => {
                return (
                  <>
                    <div className="table-cell p-2">{item.codeISO}</div>
                    <div className="table-cell p-2">{item.name}</div>
                    <div className="table-cell p-2 ">
                      <div className="flex flex-row gap-2">
                        <a
                          onClick={() => {
                            setDefaultValues(item);
                            setAdd(true);
                          }}
                        >
                          Editar
                        </a>

                        <a
                          onClick={() => {
                            Swal.fire({
                              title: "¿Eliminar el banco?",
                              text: "Se eliminra el banco y dejara de estar disponible en la aplicación",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Si",
                              cancelButtonText: "No",
                            }).then(async (result) => {
                              if (result.isConfirmed) {
                                const urlBase = `${process.env.REACT_APP_URL_BASE}/system/banks/${item.id}`;
                                return await fetch(urlBase, {
                                  method: "DELETE",
                                  headers: {
                                    Authorization: "Bearer " + token,
                                    "Content-type":
                                      "application/json; charset=UTF-8",
                                  },
                                }).finally(() => {
                                  getAll();
                                  toast.success("Se actualizo el estatus", {
                                    duration: 3000,
                                    position: "top-right",
                                  });
                                });
                              }
                            });
                          }}
                        >
                          Eliminar
                        </a>
                      </div>
                    </div>
                  </>
                );
              }}
            />
          )
        )}

        {add && (
          <FormBank
            title={"Admin banco"}
            handleSucces={getAll}
            endpoint={process.env.REACT_APP_URL_BASE}
            token={token}
            onClose={() => {
              setDefaultValues();
              setAdd(false);
            }}
            defaultValues={defaultValues}
          />
        )}
      </div>
    </div>
  );
};

export default Banks;
